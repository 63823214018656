/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from '@transifex/native';
import { AxiosResponse } from 'axios';
import {
  convertFieldsMapToViewState,
  getSavedMetadataTypeSelection,
} from '../saml/saml-utils';
import { ssoRoute } from '../sso-routes';
import {
  IOidcConnection,
  IConnectionSaml,
  IConnectionSamlViewModel,
  IConnectionViewModel,
} from './types';

/**
 * Convert IOidcConnection to IConnectionViewModel
 */
export const convertToIConnectionViewModel = (
  connection: IOidcConnection,
  isEditMode?: boolean,
): IConnectionViewModel => {
  const { id, created, name, strategy, ...rest } = connection;

  return {
    ...rest,
    ...(rest.options && {
      options: {
        ...rest.options,
        oidc: {
          ...rest.options.oidc,
          isEditMode: isEditMode,
          clientSecretInteractionMode: false,
          typeInitial: rest.options.oidc.type,
        },
      },
    }),
  };
};

export const convertToIConnectionSamlViewModel = (
  connection: IConnectionSaml,
  isEditMode?: boolean,
): IConnectionSamlViewModel => {
  const { options, displayName, emailDomains } = connection;
  const { metadataXml, metadataUrl, fieldsMap, signInEndpoint } = options.samlp;

  return {
    ...options.samlp,
    emailDomains: emailDomains || [],
    displayName: displayName || '',
    metadataSelection: getSavedMetadataTypeSelection(
      signInEndpoint,
      metadataUrl,
    ),
    metadataUrl: metadataUrl || '',
    metadataXml: metadataXml || '',
    fieldsMap: convertFieldsMapToViewState(fieldsMap),
    signInEndpoint: signInEndpoint,
  };
};

interface IConnectionPatchDTO
  extends Omit<IConnectionViewModel, 'displayName'> {}

/**
 * Convert IConnectionViewModel to IOidcConnection for Patch
 */
export const convertToIConnectionPatchDTO = (
  connection: IConnectionViewModel,
  initialValues: any,
): IConnectionPatchDTO | object => {
  const {
    emailDomains,
    organizationId,
    accountId,
    state,
    verified,
    verification,
    ...rest
  } = connection;

  return getModifiedProperties(connection, initialValues);
};

export const getModifiedProperties = (
  newObj: { [key: string]: any },
  oldObj: { [key: string]: any },
): IConnectionPatchDTO | object => {
  return Object.keys(newObj).reduce((result: { [key: string]: any }, key) => {
    if (typeof newObj[key] === 'object' && !Array.isArray(newObj[key])) {
      // If the property is an object (excluding arrays), recursively check for changes
      const nestedChanges = getModifiedProperties(
        newObj[key] ?? {},
        oldObj[key],
      );
      if (Object.keys(nestedChanges).length > 0) {
        result[key] = nestedChanges;
      }
    } else if (
      Array.isArray(newObj[key]) &&
      JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])
    ) {
      // If the property has changed, add it to the result
      result[key] = newObj[key];
    } else if (oldObj[key] !== newObj[key]) {
      result[key] = newObj[key];
    }
    return result;
  }, {});
};

export const onQueryError = ({
  error,
  toast,
  setFieldError,
}: {
  error: { isAxiosError: any; response: any };
  toast: any;
  setFieldError: any;
  values: any;
}) => {
  const { isAxiosError, response } = error;
  // if it's an API error handle it
  if (isAxiosError && response) {
    const { data } = response;
    const { errors } = data;
    let newErrors = [];

    newErrors = Object.keys(errors || []).reduce(
      (acc: any[string], next: string) => {
        // Create a path array from the key
        const keyPath = next
          .split('.')
          .map((key: string) => key.charAt(0).toLowerCase() + key.slice(1));

        acc.push({
          path: keyPath,
          message: errors[next][0],
        });

        return acc;
      },
      [],
    );

    if (newErrors?.length) {
      newErrors.forEach((error: any) => {
        const { path, message } = error;
        setFieldError(path.join('.'), message);
      });
    }

    return toast({
      title: response.data.title,
      description: response.data.detail,
      status: 'error',
    });
  }

  // Else show a generic error
  toast({
    description: t('Something went wrong'),
    status: 'error',
  });
};

const routePerStrategy = {
  oidc: 'verify-connection',
  samlp: 'configure-connection',
};

export const onQuerySuccess = (
  response: AxiosResponse<IOidcConnection> | AxiosResponse<IConnectionSaml>,
  toast: any,
  history: any,
  queryClient: any,
) => {
  const { config = {}, data: { id, strategy } = {} } = response;
  // invalidate the connections list query,
  queryClient.invalidateQueries('connections/list');
  // if it's on create mode, close the drawer and navigate to the verification connection screen
  if (config.method === 'post') {
    history.replace({
      pathname: `${ssoRoute}/${strategy}/${id}/${
        routePerStrategy[strategy as keyof typeof routePerStrategy]
      }`,
      search: history.location.search,
    });
  }

  // if it's on edit mode, close the drawer and show a toast success message
  if (config.method === 'patch') {
    history.replace({
      pathname: ssoRoute,
      search: history.location.search,
    });
    toast({
      description: 'Connection saved',
      title: 'Success',
      status: 'success',
    });
  }
};
