export const connectionsApi = {
  list_connections: {
    scope: 'identity.org_connections:read',
    url: () => '/organization/connections',
  },
  create_connection: {
    scope: 'identity.org_connections:write',
    url: () => '/organization/connections',
  },
  get_connection: {
    scope: 'identity.org_connections:read',
    url: (connectionId: string) => `/organization/connections/${connectionId}`,
  },
  delete_connection: {
    scope: 'identity.org_connections:write',
    url: (connectionId: string) => `/organization/connections/${connectionId}`,
  },
  patch_connection: {
    scope: 'identity.org_connections:write',
    url: (connectionId: string) => `/organization/connections/${connectionId}`,
  },
  verify_connection: {
    scope: 'identity.org_connections:write',
    url: (connectionId: string) =>
      `/organization/connections/${connectionId}/verify`,
  },
  activate_connection: {
    scope: 'identity.org_connections:write',
    url: (connectionId: string) =>
      `/organization/connections/${connectionId}/activate`,
  },
};
