import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Heading,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  useDisclosure,
  Input,
  Skeleton,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { customHostnamesRoute } from './routes';
import { useGetHostname } from './hooks/useGetHostname';
import { Hostname } from './types';
import { CopyClipboardIconTooltip } from 'features/common/components/CopyClipboardIconTooltip';

export const HostnameRecordsModal = () => {
  const { hostname } = useParams<{ hostname: string }>();
  const history = useHistory();

  const toast = useToast();
  const onError = () =>
    toast({
      description: 'Something went wrong',
      title: 'Error',
      status: 'error',
    });
  const { data, isFetching } = useGetHostname(hostname, { onError });

  const { onClose } = useDisclosure();
  const onCloseDrawer = useCallback(() => {
    history.push({
      pathname: customHostnamesRoute,
      search: history.location.search,
    });
    onClose();
  }, [history, onClose]);

  const {
    ssl: { validation_records },
    ownership_verification: {
      name: hostnameValidationTXTName,
      value: hostnameValidationTXTValue,
    },
  } = useMemo(
    () => (data?.data as Hostname) || { ssl: {}, ownership_verification: {} },
    [data?.data],
  );
  const { txt_name: sslValidationTXTName, txt_value: sslValidationTXTValue } =
    validation_records?.[0] ?? {};

  const initialFocusRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      isOpen={true}
      onClose={onCloseDrawer}
      data-onCloseDrawer='hostname-records-modal'
      size='2xl'
      autoFocus={false}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent data-testid='hostname-records-modal'>
        <ModalHeader>
          <Heading
            as='h4'
            size='md'
            data-testid='hostname-records-modal-header'
          >
            Hostname Records
          </Heading>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text data-testid='hostname-records-details' mb={8}>
            Copy the TXT record and SSL record and paste them in to your DNS
            records. After adding the DNS records, hostname will activate
            shortly.
          </Text>
          <form data-testid='hostname-records-form' id='hostname-records-form'>
            <Flex direction='column' gap={8}>
              <ValidationInput
                label='Hostname Validation TXT Name'
                value={hostnameValidationTXTName}
                isLoading={isFetching}
                dataTestId='hostname-txt-name-input'
              />
              <ValidationInput
                label='Hostname Validation TXT Value'
                value={hostnameValidationTXTValue}
                isLoading={isFetching}
                dataTestId='hostname-txt-value-input'
              />
              <ValidationInput
                label='SSL Validation TXT Name'
                value={sslValidationTXTName}
                isLoading={isFetching}
                dataTestId='label-ssl-txt-name'
              />
              <ValidationInput
                label='SSL Validation TXT Value'
                value={sslValidationTXTValue}
                isLoading={isFetching}
                dataTestId='label-ssl-txt-value'
              />
            </Flex>
          </form>
        </ModalBody>
        <ModalFooter justifyContent='flex-end' alignItems='flex-end'>
          <Button
            type='button'
            variant='solid'
            onClick={onCloseDrawer}
            isDisabled={isFetching}
            data-testid='hostname-records-cancelButton'
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type ValidationInputProps = {
  label: string;
  value: string;
  isLoading?: boolean;
  dataTestId?: string;
};
export const ValidationInput = ({
  label,
  value,
  isLoading = false,
  dataTestId = 'validation-input',
}: ValidationInputProps) => (
  <FormControl>
    <FormLabel data-testid={`${dataTestId}-label`}>{label}</FormLabel>
    <Skeleton isLoaded={!isLoading}>
      <InputGroup>
        <Input
          name='hostname-txt-name'
          value={value}
          isReadOnly
          data-testid={dataTestId}
        />
        <InputRightElement>
          <CopyClipboardIconTooltip
            inputValue={value}
            dataTestId={`${dataTestId}-tooltip`}
          />
        </InputRightElement>
      </InputGroup>
    </Skeleton>
  </FormControl>
);
