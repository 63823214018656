import { ctxMappingRoute } from '../../routes/ContextMappingDrawerRoute';
import { ITenant } from '@sitecore-ui/portal-singular';
import {
  AddEditContextMappingSelectOption,
  SitecoreContextResourceType,
  SitecoreContextType,
} from '../../types/types';
import {
  CUSTOMER_ENV_TYPES,
  EDGE_ENDPOINT_TYPES,
  PRODUCT_CODES,
} from '../../utils';

export const mutateOptions = (
  queryClient: any,
  history: any,
  toast: any,
  successToastDescription: string,
  errorToastDescription: string,
) => ({
  onSuccess: () => {
    queryClient.invalidateQueries('contexts/list');
    history.replace({
      pathname: ctxMappingRoute,
      search: history.location.search,
    });
    toast({
      description: successToastDescription,
      status: 'success',
    });
  },
  onError: (error: any) => {
    const errorMsg =
      error?.isAxiosError && error?.response
        ? {
            title: error.response.data.title,
            description: error.response.data.detail,
          }
        : { description: errorToastDescription };
    toast({ ...errorMsg, status: 'error' });
  },
});

export const findTenantId = (
  types: string[],
  contextData: SitecoreContextType | undefined,
  tenantsData: ITenant[] | undefined,
) => {
  if (!contextData || !tenantsData || tenantsData.length === 0) return '';
  const resource = contextData?.resources?.find((res) =>
    types.includes(res.type.toLowerCase()),
  );
  return resource &&
    tenantsData.some((tenant) => tenant.id === resource.tenantId)
    ? resource.tenantId
    : '';
};

export const findTenantDataByTenantId = (
  tenantId: string,
  tenantsData: ITenant[] | undefined,
): ITenant | undefined => {
  if (!tenantsData || tenantsData.length === 0) return undefined;
  return (
    tenantsData.find((tenant: ITenant) => tenant.id === tenantId) || undefined
  );
};

export const findContextResourceDataByTenantIdAndResourceType = (
  tenantId: string,
  resourceType: string,
  contextsData: SitecoreContextType[] | undefined,
): SitecoreContextResourceType | undefined => {
  if (!contextsData || contextsData.length === 0) return undefined;
  const context = contextsData.find((context: { resources: any[] }) =>
    context.resources.some(
      (resource) =>
        resource.type.toLowerCase() === resourceType &&
        resource.tenantId === tenantId,
    ),
  );
  return (
    context?.resources.find(
      (resource: { type: string; tenantId: string }) =>
        resource.type.toLowerCase() === resourceType &&
        resource.tenantId === tenantId,
    ) || undefined
  );
};

export const getSelectOptions = (
  tenantsData: ITenant[] | undefined,
  productCodes: string[],
): AddEditContextMappingSelectOption[] => {
  if (!tenantsData || tenantsData.length === 0) return [];
  return (
    tenantsData
      .filter((tenant: any) =>
        productCodes.includes(tenant.labels?.ProductCode?.toLowerCase()),
      )
      .map((tenant: any) => ({
        displayName: tenant.displayName,
        id: tenant.id,
      })) || []
  );
};

export const getSelectEdgeEndpointOptions = (
  contextsData: SitecoreContextType[] | undefined,
  tenantsData: ITenant[] | undefined,
) => {
  if (
    !contextsData ||
    contextsData.length === 0 ||
    !tenantsData ||
    tenantsData.length === 0
  )
    return [];

  const prodTenantId =
    contextsData
      .find((context) =>
        context.resources?.some(
          (resource) =>
            resource.type?.toLowerCase() === PRODUCT_CODES.EDGE &&
            tenantsData?.some(
              (tenant) =>
                tenant.id === resource.tenantId &&
                tenant.labels?.CustomerEnvironmentType?.toLowerCase() ===
                  CUSTOMER_ENV_TYPES.PROD,
            ),
        ),
      )
      ?.resources?.find(
        (resource) =>
          resource.type?.toLowerCase() === PRODUCT_CODES.EDGE &&
          tenantsData?.some(
            (tenant) =>
              tenant.id === resource.tenantId &&
              tenant.labels?.CustomerEnvironmentType?.toLowerCase() ===
                CUSTOMER_ENV_TYPES.PROD,
          ),
      )?.tenantId ?? '';

  const nonProdTenantId =
    contextsData
      .find((context) =>
        context.resources?.some(
          (resource) =>
            resource.type?.toLowerCase() === PRODUCT_CODES.EDGE &&
            tenantsData?.some(
              (tenant) =>
                tenant.id === resource.tenantId &&
                tenant.labels?.CustomerEnvironmentType?.toLowerCase() ===
                  CUSTOMER_ENV_TYPES.NON_PROD,
            ),
        ),
      )
      ?.resources?.find(
        (resource) =>
          resource.type?.toLowerCase() === PRODUCT_CODES.EDGE &&
          tenantsData?.some(
            (tenant) =>
              tenant.id === resource.tenantId &&
              tenant.labels?.CustomerEnvironmentType?.toLowerCase() ===
                CUSTOMER_ENV_TYPES.NON_PROD,
          ),
      )?.tenantId ?? '';

  if (prodTenantId && nonProdTenantId) {
    return [
      {
        displayName: EDGE_ENDPOINT_TYPES.LIVE,
        id: prodTenantId,
      },
      {
        displayName: EDGE_ENDPOINT_TYPES.PREVIEW,
        id: nonProdTenantId,
      },
    ];
  }
  return [];
};

export const getContextCdpPersonalizeResource = (
  tenantId: string,
  tenantData: ITenant | undefined,
) => ({
  id: 'resource-cdp',
  name: 'Sitecore Personalize',
  type: PRODUCT_CODES.PERSONALIZE,
  tenantId,
  properties: [
    { id: 'region', value: String(tenantData?.labels['RegionCode'] || '') },
    { id: 'client_key', value: String(tenantData?.labels['clientKey'] || '') },
  ],
});

export const getContextSearchResource = (
  tenantId: string,
  tenantData: ITenant | undefined,
) => ({
  id: 'resource-search',
  name: 'Sitecore Search',
  type: PRODUCT_CODES.SEARCH,
  tenantId,
  properties: [
    { id: 'domain', value: String(tenantData?.labels['domain_id'] || '') },
    { id: 'apiKey', value: String(tenantData?.annotations['ckey'] || '') },
    {
      id: 'region',
      value: String(tenantData?.annotations['TenantClaims.region'] || ''),
    },
  ],
});
