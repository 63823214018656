/* eslint-disable no-console */
import { useFormik } from 'formik';
import {
  useGetConnection,
  usePostConnection,
  usePatchConnection,
  convertToIConnectionViewModel,
  convertToIConnectionPatchDTO,
  IOidcConnection,
  onQueryError,
  onQuerySuccess,
} from '.';
import { useParams } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createConectionSchema } from 'features/sso/validations/connectionSchema';
import { useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import { ssoEditRoute } from 'features/sso/sso-routes';
import { AxiosResponse } from 'axios';
import { constructSSOPayload, redirectToConfigureModal } from '../utils';

const defaultValues = {
  oidc: {
    options: {
      oidc: {
        type: 'FrontChannel',
        clientId: '',
        clientSecret: '',
        scope: 'openid profile email',
        discoveryUrl: '',
      },
    },
    strategy: 'oidc',
    emailDomains: [],
  },
  samlp: {
    strategy: 'samlp',
    emailDomains: [],
    options: {
      samlp: {},
    },
  },
};
export const useCreateConnection = (strategy: string) => {
  // get the connection id from uri path via useParams
  const { id: connectionId } = useParams<{ id: string }>();
  // if path matches /admin/sso/:ssoProvider/:id/edit, then isInEditMode is true
  const isInEditMode = Boolean(useRouteMatch(ssoEditRoute));
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();

  // if it's on edit mode, fetch the connection data
  const {
    data: connection,
    isLoading,
    isFetched,
    error: connectionError,
  } = useGetConnection(connectionId, {
    enabled: isInEditMode,
  });

  // POST mutation
  const { mutate: postConnection, isLoading: isCreateLoading } =
    usePostConnection();

  // PATCH mutation
  const { mutate: patchConnection, isLoading: isEditLoading } =
    usePatchConnection({
      connectionId,
    });

  /**
   * Samlp will always returns the default values
   */
  const getInitialValues = () => {
    if (isFetched && connection?.data && 'oidc' in connection.data.options) {
      return convertToIConnectionViewModel(
        connection.data as IOidcConnection,
        isInEditMode,
      );
    }

    return defaultValues[strategy as keyof typeof defaultValues];
  };

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isValidating,
    isSubmitting,
    setSubmitting,
    dirty,
    setErrors,
    setFieldValue,
    setFieldTouched,
    initialValues,
  } = useFormik({
    initialValues: getInitialValues(),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values, { setFieldError }) => {
      const newValues = constructSSOPayload(strategy)(values);

      const options = {
        onSuccess: (response: AxiosResponse<IOidcConnection>) => {
          onQuerySuccess(response, toast, history, queryClient);
          setSubmitting(false);

          if (response.data.strategy === 'samlp') {
            redirectToConfigureModal(response.data.strategy, response.data.id);
          }
        },
        onError: (error: { isAxiosError: any; response: any }) => {
          onQueryError({ error, toast, setFieldError, values: newValues });
          setSubmitting(false);
        },
      };

      if (isInEditMode) {
        // @ts-ignore
        return patchConnection(
          //@ts-ignore
          convertToIConnectionPatchDTO(newValues, connection?.data),
          options,
        );
      }
      //@ts-ignore
      postConnection(newValues, options);
    },
    validationSchema:
      createConectionSchema[strategy as keyof typeof createConectionSchema],
  });

  return {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setErrors,
    setFieldTouched,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    isInEditMode,
    isLoading: isCreateLoading || isEditLoading || isLoading,
    isFetched,
    connectionError,
    initialValues,
  };
};
