import { Organization } from 'gql/graphql';
import { AzureFeatureFlagValue } from './azure-feature-flags';
import { validationCallbackPerCondition } from './utils';

function shouldEnableFlag({
  flag,
  data,
}: {
  flag: AzureFeatureFlagValue;
  data: { organization?: Organization };
}) {
  // If there are no conditions, then we should enable the flag
  if (!flag.conditions?.client_filters?.length || !flag.enabled) {
    return flag.enabled;
  }
  // If there are conditions, then we should enable the flag if any of the conditions are met
  return flag.conditions.client_filters.some(
    (condition) =>
      validationCallbackPerCondition[condition.name]?.({ condition, data }) ??
      flag.enabled,
  );
}

export { shouldEnableFlag };
