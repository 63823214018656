import { useMemo } from 'react';
import { useAuthQuery } from '../../features/common/apiUtils/queryHelper';
import config from 'features/common/config/config';
import { FlagsProvider } from './features';
import { useAuth0 } from '@auth0/auth0-react';
import { shouldEnableFlag } from './azure-feature-flags-conditions';
import { Organization } from 'gql/graphql';

/**
 * Azure Feature Flag Value
 */
export interface AzureFeatureFlagValue {
  id: string;
  enabled: boolean;
  conditions: {
    client_filters: { name: string; parameters?: Record<string, string> }[];
  };
  description?: string;
  displayName?: string;
}

/**
 * Azure Feature Flags Hook, returns the Azure Feature Flags response.
 * @param options
 * @returns {UseQueryResult<AzureFeatureFlagValue[], unknown>}
 */
function useAzureFeatureFlags(options?: Partial<{ enabled: boolean }>) {
  return useAuthQuery(
    config.featureFlags.scope,
    (axiosInstance) =>
      axiosInstance?.get<AzureFeatureFlagValue[]>(config.featureFlags.url),
    { enabled: Boolean(options?.enabled) },
  );
}

/**
 * Transforms the Azure Feature Flag response into a format that is compatible with the `FlagsProvider` component.
 * @param flags
 * @returns {Record<string, boolean>}
 */

function transformFlags({
  flags,
  data,
}: {
  flags: AzureFeatureFlagValue[];
  data: { organization?: Organization };
}) {
  const result: Record<string, boolean> = {};

  for (const flag of flags) {
    result[flag.id] = shouldEnableFlag({ flag, data });
  }

  return result;
}

type AzureFeatureProviderProps = {
  children: React.ReactNode;
  data: Organization;
};

/**
 * Azure Feature Flags Provider
 * @param children
 * @returns {JSX.Element}
 */
export function AzureFeaturesProvider({
  children,
  ...props
}: AzureFeatureProviderProps) {
  const { isAuthenticated } = useAuth0();
  const { data, isLoading } = useAzureFeatureFlags({
    enabled: isAuthenticated,
  });
  const togglePoints = useMemo(
    () =>
      transformFlags({
        flags: data?.data ?? [],
        data: { organization: props.data },
      }),
    [data, props],
  );

  if (isLoading) {
    return null;
  }

  return <FlagsProvider features={togglePoints}>{children}</FlagsProvider>;
}
