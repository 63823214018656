import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Heading,
  ModalBody,
  Flex,
  ModalFooter,
  ButtonGroup,
  Button,
  FormControl,
  FormLabel,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { customHostnamesRoute } from './routes';
import FieldError from 'features/common/components/Form/FieldError';
import {
  CreateValues,
  useHostnameCreationForm,
} from './hooks/useHostnameCreationForm';
import { FormikErrors, FormikTouched } from 'formik/dist/types';

export const HostnameCreationModal = () => {
  const history = useHistory();
  const { onClose } = useDisclosure();
  const onCloseDrawer = useCallback(() => {
    history.push({
      pathname: customHostnamesRoute,
      search: history.location.search,
    });
    onClose();
  }, [history, onClose]);

  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    isLoading,
    dirty,
  } = useHostnameCreationForm();
  const createTouched = touched as FormikTouched<CreateValues>;
  const createValues = values as CreateValues;
  const createErrors = errors as FormikErrors<CreateValues>;
  const initialFocusRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Modal
        isOpen={true}
        onClose={onCloseDrawer}
        data-onCloseDrawer='hostname-creation-modal'
        size='lg'
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent data-testid='hostname-creation-modal'>
          <ModalHeader>
            <Heading
              as='h4'
              size='md'
              data-testid='hostname-creation-modal-header'
            >
              Create custom hostname
            </Heading>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <form
              onSubmit={handleSubmit}
              data-testid='hostname-creation-form'
              id='hostname-creation-form'
            >
              <Flex direction='column' gap={6}>
                <FormControl
                  isInvalid={!!createErrors?.hostname && createTouched.hostname}
                  isRequired
                >
                  <FormLabel data-testid='label-hostname'>Hostname</FormLabel>
                  <Input
                    ref={initialFocusRef}
                    name='hostname'
                    value={createValues.hostname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid='input-hostname'
                  />
                  <FieldError
                    touched={createTouched.hostname}
                    error={createErrors.hostname}
                    testId='hostname-error'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel data-testid='label-defaultContextId'>
                    Default Context ID
                  </FormLabel>
                  <Input
                    name='defaultContextId'
                    value={values.defaultContextId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel data-testid='label-defaultContextId'>
                    Default site ID
                  </FormLabel>
                  <Input
                    name='defaultSiteId'
                    value={values.defaultSiteId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </Flex>
            </form>
          </ModalBody>
          <ModalFooter justifyContent='flex-end' alignItems='flex-end'>
            <ButtonGroup>
              <Button
                type='button'
                variant='ghost'
                data-testid='hostname-creation-cancelButton'
                onClick={onCloseDrawer}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='solid'
                data-testid='hostname-creation-submitButton'
                isLoading={isLoading}
                isDisabled={!isValid || !dirty}
                form='hostname-creation-form'
              >
                Create
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
