import { Skeleton, Button, Stack, IconButton, Box } from '@chakra-ui/react';
import MediaCard from '../MediaCard/MediaCard';
import { PortalIcon } from '../PortalIcon';
import { mdiClose } from '@mdi/js';
import { Select as ReactSelect } from 'chakra-react-select';
import { userAccessLimit } from 'features/members/utils/lib';
import { UserAppAccessLimitAlert } from 'features/members/components/table/shared/UserAppAccessLimitAlert';

type ProductAccessOption = {
  title: any;
  subTitle: string;
  icon: {
    iconUrl: any;
  };
  productCode: string;
  id: string;
  organizationId: string;
  value: string;
  roles: {
    value: string;
    label: string;
  }[];
};
export type ProductAccessOptions = ProductAccessOption[];

type ProductAccessRowType = {
  product: ProductAccessOption;
  accessType: {
    value: string;
    label: string;
  };
};
export type ProductAccessRowStateType = ProductAccessRowType[];

export const ProductAccessOptions = ({
  productAccessRowState,
  setproductAccessRowState,
  productAccessOptions,
  isLoading,
}: {
  productAccessRowState: ProductAccessRowStateType;
  productAccessOptions: ProductAccessOptions;
  setproductAccessRowState: (arg0: ProductAccessRowStateType) => void;
  isLoading: boolean;
}) => {
  const addProductHandler = () => {
    setproductAccessRowState([
      ...productAccessRowState,
      {
        product: productAccessOptions?.[0],
        // we want the User to be selected if its available otherwise whatever comes first in the options list
        accessType:
          productAccessOptions[0].roles.find(
            (access) => access?.value === 'User',
          ) ?? productAccessOptions[0].roles[0],
      },
    ]);
  };

  const removeProductHandler = (productToRemove: number) => {
    const reducedProductRows = productAccessRowState.filter(
      (_, index) => index !== productToRemove,
    );

    setproductAccessRowState(reducedProductRows);
  };

  const onSelectProductHandler = (
    selectionIndex: number,
    selection: ProductAccessOption,
  ) => {
    setproductAccessRowState(
      productAccessRowState.map((p: ProductAccessRowType, index) => {
        return {
          ...p,
          ...(index === selectionIndex && {
            product: {
              ...selection,
            },
            accessType: {
              ...(selection.roles.find((access) => access?.value === 'User') ??
                selection.roles[0]),
            },
          }),
        };
      }),
    );
  };

  const onSelectAccessHandler = (
    selectionIndex: number,
    selection: {
      value: string;
      label: string;
    } | null,
  ) => {
    setproductAccessRowState(
      productAccessRowState.map((p: ProductAccessRowType, index) => {
        return {
          ...p,
          ...(index === selectionIndex && {
            accessType: {
              ...p.accessType,
              ...selection,
            },
          }),
        };
      }),
    );
  };

  const productAccessRows = productAccessRowState.map(
    (s: ProductAccessRowType, index) => {
      const { product, accessType } = s;
      return {
        accessOptions: product.roles,
        product,
        accessType: accessType,
        index,
      };
    },
  );

  const userHasReachedTheLimit =
    productAccessRowState.length >= userAccessLimit;
  return (
    <Skeleton isLoaded={!isLoading}>
      {productAccessRows.map(
        ({ accessOptions, product, accessType, index }) => {
          return (
            <Stack
              spacing='4'
              direction={['column', 'row']}
              pb='4'
              alignItems='center'
              key={index}
            >
              <Box w={'full'} data-testid='inviteDrawer-select-tenant-access'>
                <ReactSelect
                  selectedOptionColorScheme='primary'
                  useBasicStyles
                  name={`invitation-product-select-${index}`}
                  inputId={`invitation-product-select-input-id-${index}`}
                  classNamePrefix='invitation-product-select'
                  value={product}
                  onChange={(data) => {
                    if (data) {
                      onSelectProductHandler(index, data);
                    }
                  }}
                  options={productAccessOptions}
                  formatOptionLabel={({ title, subTitle, icon }) => {
                    return (
                      <MediaCard
                        size='option'
                        title={title}
                        imageUrl={icon.iconUrl}
                        label={subTitle}
                      />
                    );
                  }}
                />
              </Box>
              <Box minW={44}>
                <ReactSelect
                  selectedOptionColorScheme='primary'
                  useBasicStyles
                  name={`invitation-access-select-${index}`}
                  inputId={`invitation-access-select-input-id-${index}`}
                  classNamePrefix='invitation-access-select'
                  value={{ value: accessType.value, label: accessType.label }}
                  onChange={(data) => {
                    onSelectAccessHandler(index, data);
                  }}
                  options={accessOptions}
                />
              </Box>
              <IconButton
                variant='ghost'
                size='sm'
                aria-label='remove product'
                icon={<PortalIcon path={mdiClose} />}
                onClick={() => removeProductHandler(index)}
              />
            </Stack>
          );
        },
      )}
      {userHasReachedTheLimit ? (
        <UserAppAccessLimitAlert />
      ) : productAccessOptions?.length > 0 ? (
        <Button
          type='button'
          variant='outline'
          size='md'
          onClick={addProductHandler}
          my='4'
          data-testid='invitation-add-product-button'
        >
          Add app
        </Button>
      ) : (
        <Button
          type='button'
          variant='outline'
          size='md'
          isDisabled={true}
          my='4'
        >
          No more apps available.
        </Button>
      )}
    </Skeleton>
  );
};
