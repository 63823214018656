import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { T } from '@transifex/react';
import { useHistory } from 'react-router-dom';
import { ctxMappingRoute } from '../../routes/ContextMappingDrawerRoute';
import DiscardChangesModal from '../../../common/components/DiscardModal/DiscardChangesModal';
import AddEditContextMappingDrawerBody from './AddEditContextMappingDrawerBody';
import { useAddEditContextMapping } from '../../hooks/useAddEditContextMapping';

export const AddEditContextMappingDrawer = () => {
  const history = useHistory();
  const { isOpen, onClose, onOpen: onOpenDiscard } = useDisclosure();
  const [isDirty] = useState(false);

  const onCloseDrawer = useCallback(() => {
    if (isDirty) return onOpenDiscard();
    history.push({
      pathname: ctxMappingRoute,
      search: history.location.search,
    });
    onClose();
  }, [history, isDirty, onClose, onOpenDiscard]);

  const onDiscard = useCallback(() => {
    onClose();
    history.push({
      pathname: ctxMappingRoute,
      search: history.location.search,
    });
  }, [history, onClose]);

  const {
    isInEditMode,
    isWithXmCloudContext,
    values,
    edgeEndpointSelectOptions,
    cdpPersonalizeTenantSelectOptions,
    searchTenantSelectOptions,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    isLoading,
    isSubmitButtonDisabled,
  } = useAddEditContextMapping();

  return (
    <>
      <Drawer onClose={onCloseDrawer} isOpen={true} size='lg'>
        <form
          onSubmit={handleSubmit}
          data-testid='add-edit-context-mapping-drawer-form'
        >
          <DrawerOverlay />
          <DrawerContent
            data-testid='add-edit-context-mapping-drawer-content'
            data-behavior-analytics-feature='Add edit context mapping drawer'
          >
            <DrawerHeader display='flex' alignItems='center'>
              <T _str={`${isInEditMode ? 'Edit' : 'Add'} context mapping`} />
            </DrawerHeader>
            <AddEditContextMappingDrawerBody
              isWithXmCloudContext={isWithXmCloudContext}
              values={values}
              edgeEndpointSelectOptions={edgeEndpointSelectOptions}
              cdpPersonalizeTenantSelectOptions={
                cdpPersonalizeTenantSelectOptions
              }
              searchTenantSelectOptions={searchTenantSelectOptions}
              setFieldValue={setFieldValue}
              isValid={isValid}
              dirty={dirty}
              isLoading={isLoading}
              isSubmitButtonDisabled={isSubmitButtonDisabled}
              onCloseDrawer={onCloseDrawer}
            />
          </DrawerContent>
        </form>
      </Drawer>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={onClose}
        onDiscard={onDiscard}
      />
    </>
  );
};
