import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import { createHostnameSchema } from '../utils';
import { useCreateHostname } from './useCreateHostname';
import { EdgeApiError } from '../types';
import { useHistory } from 'react-router';
import { customHostnamesRoute } from '../routes';

export type CreateValues = {
  hostname: string;
  defaultSiteId: string;
  defaultContextId: string;
};

const defaultValues: CreateValues = {
  hostname: '',
  defaultSiteId: '',
  defaultContextId: '',
};

export const useHostnameCreationForm = () => {
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: createHostname, isLoading: isCreateHostnameLoading } =
    useCreateHostname();
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setSubmitting,
    isValid,
    isValidating,
    isSubmitting,
    dirty,
    initialValues,
  } = useFormik({
    initialValues: defaultValues,
    onSubmit: (values, { setFieldError }) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries('hostnames/list');
          toast({
            description: 'Hostname has been registered',
            status: 'success',
          });
          setSubmitting(false);
          history.push({
            pathname: customHostnamesRoute,
            search: history.location.search,
          });
        },
        onError: (error: AxiosError<EdgeApiError>) => {
          setFieldError('hostname', error?.response?.data?.detail);
          toast({
            description: 'Something went wrong',
            title: 'Error',
            status: 'error',
          });
          setSubmitting(false);
        },
      };
      //@ts-ignore
      createHostname(values, options);
    },
    validationSchema: createHostnameSchema,
  });

  return {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setSubmitting,
    isValid,
    isValidating,
    isSubmitting,
    isLoading: isValidating || isSubmitting || isCreateHostnameLoading,
    dirty,
    initialValues,
  };
};
