import { useAuthMutation } from 'features/common/apiUtils/queryHelper';
import { connectionsApi } from './endpoints';
import { getIdentityBaseUrl } from '@sitecore-ui/portal-singular';
import { env } from 'features/common/config/envConfig';
import { IOidcConnection } from 'features/sso/api/types';

const baseUrl = getIdentityBaseUrl(env.toLowerCase());

export const usePostConnection = () => {
  const { create_connection } = connectionsApi;
  const url = `${baseUrl}${create_connection.url()}`;

  const result = useAuthMutation(
    (axiosInstance) => (payload: IOidcConnection) =>
      axiosInstance?.post(url, payload),
    {
      onSuccess: () => void 0,
      onError: () => void 0,
    },
    create_connection.scope,
  );

  return result;
};
