import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PortalIcon } from '../../../../common/components/PortalIcon';
import { mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js';
import { history } from '../../../../common/history/history';
import {
  getCtxMappingDeleteRoute,
  getCtxMappingEditRoute,
} from '../../../routes/ContextMappingDrawerRoute';
import {
  SitecoreContextMappingType,
  SitecoreContextType,
  TenantType,
} from '../../../types/types';
import {
  CUSTOMER_ENV_TYPES,
  EDGE_ENDPOINT_TYPES,
  PRODUCT_CODES,
} from '../../../utils';

export const contextMappingTableButtonsAccessor = (
  sitecoreContextId: string,
  sitecoreContextType: string,
  isAllowEdit: boolean,
) => {
  return (
    <ButtonGroup flexDir='row' justifyContent='flex-end' size='sm' width='full'>
      {!isAllowEdit && (
        <IconButton
          variant='ghostColorOnHover'
          colorScheme='danger'
          aria-label='edit context mapping'
          icon={<PortalIcon path={mdiPencilOutline} />}
          onClick={() =>
            history.push({
              pathname: getCtxMappingEditRoute(sitecoreContextId),
              search: history.location.search,
            })
          }
        />
      )}
      {sitecoreContextType === 'custom' && (
        <IconButton
          variant='ghostColorOnHover'
          colorScheme='danger'
          aria-label='delete context mapping'
          icon={<PortalIcon path={mdiTrashCanOutline} />}
          onClick={() =>
            history.push({
              pathname: getCtxMappingDeleteRoute(sitecoreContextId),
              search: history.location.search,
            })
          }
        />
      )}
    </ButtonGroup>
  );
};

type GetTableOptions = {
  withXmCloudContext: boolean;
};

export const getTableOptions = ({ withXmCloudContext }: GetTableOptions) => {
  return {
    columns: [
      {
        Header: <T _str='Context ID' />,
        accessor: 'sitecoreContextId',
        disableSortBy: true,
      },
      ...(withXmCloudContext
        ? [
            {
              Header: <T _str='Edge Endpoint' />,
              accessor: 'edgeEndpoint',
              disableSortBy: true,
            },
          ]
        : []),
      {
        Header: <T _str='Personalize Tenant' />,
        accessor: 'personalizeTenant',
        disableSortBy: true,
      },
      {
        Header: <T _str='Search Tenant' />,
        accessor: 'searchTenant',
        disableSortBy: true,
      },
      {
        id: 'context-mapping-table-buttons',
        Header: '',
        accessor: ({
          sitecoreContextId,
          type,
          personalizeTenant,
          searchTenant,
          edgeEndpoint,
        }: SitecoreContextMappingType) =>
          contextMappingTableButtonsAccessor(
            sitecoreContextId,
            type,
            withXmCloudContext
              ? personalizeTenant !== 'Not Set' &&
                  searchTenant !== 'Not Set' &&
                  edgeEndpoint !== 'Not Set'
              : personalizeTenant !== 'Not Set' && searchTenant !== 'Not Set',
          ),
        disableSortBy: true,
      },
    ],
  };
};

export const mapPersonalizeTenant = (
  resourceType: string,
  productCode: string,
  tenant: TenantType,
): string => {
  const validTypes = [
    PRODUCT_CODES.PERSONALIZE,
    PRODUCT_CODES.CDP,
    PRODUCT_CODES.PERSONALIZE_EMBEDDED,
  ];
  const isValid =
    validTypes.includes(resourceType) && validTypes.includes(productCode);
  return isValid ? tenant.displayName : '';
};

export const mapEdgeEndpoint = (
  resourceType: string,
  productCode: string,
  tenant: TenantType,
): string => {
  if (
    (resourceType === PRODUCT_CODES.EDGE ||
      resourceType === PRODUCT_CODES.XM_CLOUD) &&
    (productCode === PRODUCT_CODES.EDGE ||
      productCode === PRODUCT_CODES.XM_CLOUD)
  ) {
    switch (tenant.labels.CustomerEnvironmentType?.toLowerCase()) {
      case CUSTOMER_ENV_TYPES.PROD:
        return EDGE_ENDPOINT_TYPES.LIVE;
      case CUSTOMER_ENV_TYPES.NON_PROD:
        return EDGE_ENDPOINT_TYPES.PREVIEW;
      default:
        return '';
    }
  }
  return '';
};

export const mapSearchTenant = (
  resourceType: string,
  productCode: string,
  tenant: TenantType,
): string => {
  if (
    resourceType === PRODUCT_CODES.SEARCH &&
    productCode === PRODUCT_CODES.SEARCH
  ) {
    return tenant.displayName;
  }
  return '';
};

export const generateContextsTableData = (
  contexts: SitecoreContextType[] = [],
  tenantsData: TenantType[] = [],
) => {
  if (
    (!contexts || contexts?.length === 0) &&
    (!tenantsData || tenantsData?.length === 0)
  ) {
    return [];
  }

  return contexts.map((context) => {
    let edgeEndpoint = 'Not Set';
    let personalizeTenant = 'Not Set';
    let searchTenant = 'Not Set';

    context.resources.forEach((resource) => {
      const tenant = tenantsData.find(
        (tenant) => tenant.id === resource.tenantId,
      );

      if (tenant) {
        const resourceType = resource.type.toLowerCase();
        const productCode = tenant.labels.ProductCode.toLowerCase();

        const mappedPersonalizeTenant = mapPersonalizeTenant(
          resourceType,
          productCode,
          tenant,
        );
        if (mappedPersonalizeTenant) {
          personalizeTenant = mappedPersonalizeTenant;
        }

        const mappedEdgeEndpoint = mapEdgeEndpoint(
          resourceType,
          productCode,
          tenant,
        );
        if (mappedEdgeEndpoint) {
          edgeEndpoint = mappedEdgeEndpoint;
        }

        const mappedSearchTenant = mapSearchTenant(
          resourceType,
          productCode,
          tenant,
        );
        if (mappedSearchTenant) {
          searchTenant = mappedSearchTenant;
        }
      }
    });

    return {
      sitecoreContextId: context.sitecoreContextId,
      organizationId: context.organizationId,
      type: context.type,
      accountId: context.accountId,
      edgeEndpoint,
      personalizeTenant,
      searchTenant,
    };
  });
};
