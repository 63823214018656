import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { AddEditContextMappingSelectOption } from '../../types/types';

type ContextMappingDrawerBodyPropTypes = {
  isWithXmCloudContext: boolean;
  values: any;
  edgeEndpointSelectOptions: AddEditContextMappingSelectOption[];
  cdpPersonalizeTenantSelectOptions: AddEditContextMappingSelectOption[];
  searchTenantSelectOptions: AddEditContextMappingSelectOption[];
  setFieldValue: any;
  isValid: boolean;
  dirty: boolean;
  isLoading: boolean;
  isSubmitButtonDisabled: () => boolean;
  onCloseDrawer: () => void;
};

const AddEditContextMappingDrawerBody = ({
  isWithXmCloudContext,
  values,
  edgeEndpointSelectOptions,
  cdpPersonalizeTenantSelectOptions,
  searchTenantSelectOptions,
  setFieldValue,
  isValid,
  dirty,
  isLoading,
  isSubmitButtonDisabled,
  onCloseDrawer,
}: ContextMappingDrawerBodyPropTypes) => {
  return (
    <>
      <DrawerBody>
        <Text mb={5} data-testid='add-edit-context-mapping-drawer-description'>
          Choose the tenants you want to link together with context mapping.
        </Text>
        {isWithXmCloudContext && (
          <FormControl py={2}>
            <FormLabel>
              <T _str='Edge endpoint' />
            </FormLabel>
            <Select
              name='selectedEdgeEndpointValue'
              onChange={(e) =>
                setFieldValue('selectedEdgeEndpointValue', e.target.value)
              }
              data-testid='add-edit-context-mapping-drawer-edge-endpoint-select'
              placeholder='Select'
              value={values.selectedEdgeEndpointValue}
              isDisabled={values.isEdgeEndpointDisabled}
            >
              {edgeEndpointSelectOptions.map((opt) => (
                <option key={opt.id} value={opt.id}>
                  {opt.displayName}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl py={2}>
          <FormLabel>
            <T _str='CDP/Personalize tenant' />
          </FormLabel>
          <Select
            name='selectedCdpPersonalizeTenantValue'
            onChange={(e) =>
              setFieldValue('selectedCdpPersonalizeTenantValue', e.target.value)
            }
            data-testid='add-edit-context-mapping-drawer-cdp-personalize-tenant-select'
            placeholder='Select'
            value={values.selectedCdpPersonalizeTenantValue}
            isDisabled={values.isCdpPersonalizeTenantDisabled}
          >
            {cdpPersonalizeTenantSelectOptions.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.displayName}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl py={2}>
          <FormLabel>
            <T _str='Search tenant' />
          </FormLabel>
          <Select
            name='selectedSearchTenantValue'
            onChange={(e) =>
              setFieldValue('selectedSearchTenantValue', e.target.value)
            }
            data-testid='add-edit-context-mapping-drawer-search-tenant-select'
            placeholder='Select'
            value={values.selectedSearchTenantValue}
            isDisabled={values.isSearchTenantDisabled}
          >
            {searchTenantSelectOptions.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.displayName}
              </option>
            ))}
          </Select>
        </FormControl>
      </DrawerBody>
      <DrawerFooter justifyContent='flex-end'>
        <ButtonGroup>
          <Button
            data-testid='add-edit-context-mapping-drawer-cancel-btn'
            type='button'
            variant='ghost'
            onClick={onCloseDrawer}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            data-testid='add-edit-context-mapping-drawer-save-btn'
            isDisabled={!isValid || !dirty || isSubmitButtonDisabled()}
            isLoading={isLoading}
          >
            Save
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

export default AddEditContextMappingDrawerBody;
