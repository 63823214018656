import { useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ProfileContainer from '../../profile/components/ProfileContainer';
import AdminContainer from '../../admin/components/AdminContainer';
import { TopBar } from '../../topbar/components/TopBar';
import HomeContainer from '../../home/components/HomeContainer';
import { AdminOwnerRoute } from 'features/common/components/OrganizationAppAccess';
import { errorCallback } from '../../common/apiUtils/queryHelper';
import { InvitationAuth } from './InvitationAuth';
import {
  NoAccessPage,
  PageNotFound,
  NoOrgAccessPage,
  InvalidInvitationPage,
  ErrorPage,
} from './CustomErrorPages';
import { useOrgEnvironmentDeps } from '../../common/utils/orgEnvironments';
import { DrawerManager } from './DrawerManager/DrawerManager';
import { ApmRoute } from '@elastic/apm-rum-react';
import {
  LayoutHeader,
  LayoutWrapper,
} from 'features/common/components/Layouts';
import { AzureFeaturesProvider } from 'lib/featureFlags/azure-feature-flags';
import { SSOProvider } from 'features/sso';
import ScriptInjector from 'features/common/components/ScriptInjector';
import {
  getGainsightScript,
  useGainsight,
} from 'features/domains/gainsight/gainsight';
import { LoadingScreen } from 'features/common/components/LoadingScreen';
import { useToast } from '@chakra-ui/react';
import { history } from '../../common/history/history';
import { env } from 'features/common/config/envConfig';
import { ApolloProvider } from 'lib/@apollo/client/ApolloProvider';
import { PersonalizeTracking } from 'lib/personalize/personalizeTracking';
import Subscriptions from 'features/subscriptions';
import SubscriptionDetails from 'features/subscriptions/SubscriptionDetails';
import {
  subscriptionRoute,
  subscriptionsRoute,
} from 'features/subscriptions/routes';
import { Organization, useGetCurrentUserQuery } from 'gql/graphql';
import { restrictedSitecoreEmails } from 'features/common/validations/matchers';
import { useSetDocumentTitle } from 'features/common/hooks/useSetDocumentTitle';

export const MainContainerComp = () => {
  useSetDocumentTitle();
  const { organization } = useOrgEnvironmentDeps();
  const { data: { user } = {} } = useGetCurrentUserQuery();

  const orgIsNotInternal = organization && organization.type !== 'INTERNAL';
  const userIsNotInternal = user && !restrictedSitecoreEmails.test(user.email);
  const shouldInitializeGainsight =
    env?.toLowerCase() === 'production'
      ? orgIsNotInternal && userIsNotInternal
      : true;

  /* TEMPORARY FOR TRIGGERING ERROR TOASTS */
  const { search } = useLocation();
  const toast = useToast();

  const gainsightScript = getGainsightScript();
  useGainsight();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const fakeCode = params.get('fakeCode');
    const fakeScope = params.get('fakeScope');
    if (fakeCode && fakeScope) {
      const err = { response: { status: parseInt(fakeCode) } };
      setTimeout(() => {
        errorCallback(err, fakeScope, toast);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AzureFeaturesProvider data={organization as Organization}>
      <SSOProvider>
        {(env?.toLowerCase() === 'staging' ||
          env?.toLowerCase() === 'production') && <PersonalizeTracking />}
        {shouldInitializeGainsight && (
          <ScriptInjector code={gainsightScript} id='gainsight' />
        )}
        <LayoutWrapper>
          <LayoutHeader overflow={'visible'}>
            <TopBar />
          </LayoutHeader>
          <Switch>
            <ApmRoute
              exact
              path={subscriptionsRoute}
              component={Subscriptions}
            />
            <ApmRoute
              path={subscriptionRoute}
              component={SubscriptionDetails}
            />
            <Redirect
              from='/admin/members/invites'
              to={`/admin/invites/page/1${history.location.search}`}
            />
            <AdminOwnerRoute path='/admin' component={AdminContainer} />
            <Redirect
              from='/profile/manage'
              to={`/profile${history.location.search}`}
            />
            <ApmRoute path='/profile' component={ProfileContainer} />
            <ApmRoute exact path='/no-access' component={NoAccessPage} />
            <ApmRoute exact path='/' component={HomeContainer} />
            <ApmRoute component={PageNotFound} />
          </Switch>
          <ApmRoute component={DrawerManager} />
        </LayoutWrapper>
      </SSOProvider>
    </AzureFeaturesProvider>
  );
};

const AuthMainContainer = withAuthenticationRequired(MainContainerComp, {
  onRedirecting: () => (
    <LoadingScreen data-testid='loading-component' message='Loading…' />
  ),
});

export const MainContainer = () => {
  return (
    <ApolloProvider>
      <Switch>
        <ApmRoute
          exact
          path='/no-organization-access'
          component={NoOrgAccessPage}
        />
        <ApmRoute
          exact
          path='/no-valid-invitation'
          component={InvalidInvitationPage}
        />
        <ApmRoute exact path='/something-went-wrong' component={ErrorPage} />
        <ApmRoute path='/account/login' component={InvitationAuth} />
        <ApmRoute path='*' component={AuthMainContainer} />
      </Switch>
    </ApolloProvider>
  );
};
