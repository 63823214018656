import { Flex, Heading, Text, Button } from '@chakra-ui/react';
import { CustomHostnamesTable } from './CustomHostnamesTable';
import { useHistory } from 'react-router';
import { customHostnamesCreateRoute } from './routes';

export const CustomHostnames = () => {
  const history = useHistory();
  return (
    <Flex flexDir='column' height='100%'>
      <Flex justifyContent='space-between' width='100%'>
        <Flex direction='column' alignSelf='start'>
          <Heading as='h1' data-testid='customHostnames-heading'>
            Custom hostnames
          </Heading>
          <Text maxW='prose' my='8' data-testid='customHostnames-description'>
            Register your custom hostname so you can get access for services
            that are behind a proxy.
          </Text>
        </Flex>
        <Button
          variant='solid'
          flexShrink={0}
          data-testid='create-custom-hostname-button'
          data-behavior-analytics-id='create custom hostname'
          onClick={() =>
            history.push({
              pathname: customHostnamesCreateRoute,
              search: history.location.search,
            })
          }
        >
          Create custom hostname
        </Button>
      </Flex>
      <CustomHostnamesTable />
    </Flex>
  );
};
